@import '../../Style/breakpoints.scss';
@import '../../Style/theme.scss';

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card {
    position: relative;
    width: 232px;
    height: 450px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;

    @media (max-width: $breakpoint-sm) {
        width: 100%;
    }
}

.icon {
    position: absolute;
    z-index: 1;
    right: 1.5rem;
    top: 2rem;
}

.imageContainer {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 80%; /* Adjust this percentage to leave space for the info section */
}

.firstImage,
.secondImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.4s ease-in-out;
}

.firstImage {
    opacity: 1;
}

.secondImage {
    opacity: 0;
}

.card:hover .firstImage {
    opacity: 0;
}
.card:hover .secondImage {
    opacity: 1;
}

.info {
    position: relative;
    height: 20%; /* Adjust this percentage to match the space left by the image */
    margin-top: 10px;
    display: flex;
    gap: 10px;
    letter-spacing: 0.6px;
    &__price,
    &__subcategory {
        color: $gray-900-translucent;
        font-size: 14px;
    }
}
