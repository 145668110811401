@import '../../Style/breakpoints.scss';

.home {
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media (max-width: $breakpoint-sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 40px;
    }

    @media (max-width: $breakpoint-xs) {
        grid-template-columns: repeat(1, 1fr);
    }
}
