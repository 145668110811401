@import '../../Style/breakpoints.scss';
@import '../../Style/theme.scss';

.imageGallery {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    flex: 1 1 50%;
    position: relative;
}

.mainSlider {
    max-width: 80%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.tryOn {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: 180px;
    transform: translateX(-10%);
    cursor: pointer;

    > svg,
    g {
        cursor: pointer;
    }

    @media (max-width: $breakpoint-md) {
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.mobileSlider {
    width: 100%;
    height: 100%;
    margin-bottom: 32px;
    overflow: hidden;
    box-sizing: border-box;
}

.thumbnails {
    display: flex;
    flex-direction: column;
    width: 20%;
    @media (max-width: $breakpoint-md) {
        display: none;
    }
}

.image {
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.thumb {
    width: 100%;
    height: auto;
    cursor: pointer;
    box-sizing: border-box;
}
.slick-dots {
    bottom: 15px !important;
    left: 50% !important;
    transform: translateX(-50%);

    li {
        margin: 0 5px;

        button {
            &:before {
                font-size: 0 !important;
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                background-color: $gray-300;
                border-radius: 50%;
            }
        }

        &.slick-active button::before {
            background-color: $emerald-400;
        }
    }
}
