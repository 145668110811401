@import '../../Style/theme.scss';

.skeletonCard {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    padding: 16px;
    width: 242px;
    height: 440px;
}

.skeletonImage {
    width: 100%;
    height: 360px;
    background: $gray-300;
    border-radius: 8px;
    animation: pulse 1.5s infinite;
}

.skeletonInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.skeletonTitle,
.skeletonSubcategory {
    height: 20px;
    background: $gray-300;
    border-radius: 4px;
    animation: pulse 1.5s infinite;
}

.skeletonTitle {
    width: 60%;
}

.skeletonSubcategory {
    width: 40%;
}

@keyframes pulse {
    0% {
        background-color: $gray-300;
    }
    50% {
        background-color: gray-100;
    }
    100% {
        background-color: $gray-300;
    }
}
