@import '../../Style/theme.scss';
@import '../../Style/typography.scss';
@import '../../Style/breakpoints.scss';

.login {
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: 0 20px;

    @media (max-width: $breakpoint-md) {
        max-width: 100% !important;
    }

    @media (max-width: $breakpoint-sm) {
        height: 100%;
    }

    &__container {
        display: flex;
        border-radius: 20px;
        box-shadow: 0px 0px 30px 20px rgba(28, 39, 49, 0.0784313725);
        height: 700px;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;

        @media (max-width: $breakpoint-md) {
            flex-direction: column;
            row-gap: 25px;
            box-shadow: none;
        }

        @media (max-width: $breakpoint-sm) {
            height: 100%;
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }

    &__form-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        background-color: $white;
        padding: 80px;
        border-radius: 20px 0 0 20px;

        @media (max-width: $breakpoint-xl) {
            padding: 40px;
        }

        @media (max-width: $breakpoint-lg) {
            padding: 20px;
        }

        @media (max-width: $breakpoint-md) {
            box-shadow: -1px 0px 40px 10px rgba(28, 39, 49, 0.08);
            border-radius: 10px;
        }
    }

    &__form {
        margin-top: auto;
    }

    &__title {
        margin-bottom: 32px;
        font-size: 24px !important;
        text-align: center;
        font-weight: 600 !important;
    }

    &__input {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 4px;
        padding: 10px;
        border: 1px solid $mercury-300;
        border-radius: 4px;

        &--error {
            border-color: red;
        }
    }

    &__error {
        color: red;
        padding-bottom: 8px;
    }

    &__button {
        font-family: $font-family-tertiary;
        background-color: $emerald-500;
        border-radius: 20px;
        border: none;
        color: $white;
        cursor: pointer;
        display: block;
        font-size: 14px;
        margin-top: 16px;
        padding: 12px;
        text-align: center;
        width: 100%;
    }

    &__password-input-container {
        position: relative;
    }

    &__forgot-password {
        text-align: center;
        margin-top: 16px;
        a {
            font-size: 16px;
            color: $emerald-500;
        }
    }

    &__toggle-password {
        position: absolute;
        right: 15px;
        bottom: 6px;
        cursor: pointer;
        font-family: $font-family-secondary;
    }

    &__create-account {
        display: block;
        font-family: $font-family-secondary;
        text-align: center;
        color: $mercury-500;
        margin-top: auto;
        font-size: 16px;
        @media (max-width: $breakpoint-md) {
            margin: 36px 0;
        }
    }

    &__welcome-section {
        border-radius: 0 20px 20px 0;
        flex: 1;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-image: url('../../Assets/Img/welcome.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 80px;

        @media (max-width: $breakpoint-xl) {
            padding: 40px;
        }

        @media (max-width: $breakpoint-lg) {
            padding: 20px;
        }

        @media (max-width: $breakpoint-md) {
            padding: 40px 20px;
            border-radius: 10px;
        }
    }

    &__welcome-title {
        font-size: 45px !important;
        text-align: left;
        font-weight: 500 !important;
        padding: 40px 0;
        @media (max-width: $breakpoint-md) {
            display: none;
        }
    }

    &__welcome-logo {
        @media (max-width: $breakpoint-md) {
            display: none;
        }
    }

    &__feature-list {
        font-family: $font-family-tertiary;
        list-style: none;
        padding: 0;
    }

    &__feature-item {
        display: flex;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__feature-title {
        font-size: 16px;
    }

    &__feature-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: cover;
        margin-right: 10px;
    }

    &__feature-description {
        display: inline-block;
        vertical-align: top;
    }

    .account-password-incorrect {
        color: red;
        text-align: center;
    }
}
