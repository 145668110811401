//#region Fonts
$font-family: 'Hanken Grotesk';

$emerald-400: #50c878;
$emerald-500: #2eb67d;

$mercury-200: #e4e4e4;
$mercury-300: #e5e5e6;
$mercury-400: #adadad;
$mercury-500: #8a9192;
$mercury-950: #363636;

$gray-100: #f0f0f0;
$gray-200: #f5f5f5;
$gray-300: #e0e0e0;
$gray-900-translucent: #121212bf;
$gray-900: #121212;

$white: #ffffff;
$black: #000000;
//#endregion
