$font-family-primary: 'Hanken Grotesk', sans-serif;
$font-family-secondary: 'Poppins', sans-serif;
$font-family-tertiary: 'Montserrat', sans-serif;

// Font Weight Variables
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font Size Variables
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-large: 18px;
$font-size-xlarge: 24px;

.text {
    &-body {
        font-family: $font-family-primary;
        font-weight: $font-weight-regular;
        font-size: $font-size-base;
    }

    &-heading {
        font-family: $font-family-secondary;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
    }

    &-label {
        font-family: $font-family-primary;
        font-weight: $font-weight-medium;
        font-size: $font-size-small;
    }

    &-bold {
        font-weight: 700;
    }

    &-small {
        font-size: 12px;
    }
}
