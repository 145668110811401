@import '../../Style/theme.scss';
@import '../../Style/breakpoints.scss';

.product {
    overflow: hidden;
    padding: 0 16px 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &__container {
        display: flex;
        height: 100%;
        padding: 0 0 20px;
        position: relative;

        @media (max-width: $breakpoint-md) {
            flex-direction: column;
        }
    }

    &__details {
        flex: 1 1 50%;
        @media (max-width: $breakpoint-md) {
            flex: 1 1 100%;
        }
    }

    &__tryOn {
        position: absolute;
        left: 270px;
        height: 300px;
        width: 300px;
        z-index: 100;
        top: 70%;
        height: fit-content;
        > svg,
        g {
            cursor: pointer;
        }
    }

    &__details {
        padding: 0 26px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        color: $gray-900;
        padding-bottom: 16px;
    }

    &__sizeButtonsContainer {
        display: flex;
        gap: 10px;
        margin: 20px 0;
        flex-wrap: wrap;
    }

    &__price {
        margin: 20px 0;
        font-size: 15px;
    }

    &__label {
        letter-spacing: 0.6px;
        font-size: 13px !important;
    }

    &__productDetails {
        margin-top: 7rem;
        font-size: 14px;
        letter-spacing: 0.6px;
    }

    &__description {
        padding-top: 2px;
        border-top: 1px solid $emerald-400;
        width: 70%;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            height: 180px;
            width: 180px;
            border-radius: 50%;
            border: 1px solid $emerald-400;
            bottom: -65px;
            left: 100%;
        }
    }
}

.iframeContainer {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100dvw;
    height: 100dvh;
    background-color: #01010180;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    @media (max-width: $breakpoint-sm) {
        padding: 0;
    }
}

.iframe {
    width: 512px;
    max-height: 832px;
    height: 100%;
    border-radius: 20px;
    border: none;
    background-color: transparent;

    @media (max-width: $breakpoint-sm) {
        width: 100vw;
        height: 100dvh;
        border-radius: 0;
        max-height: none;
    }
}
