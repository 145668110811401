@import './breakpoints.scss';

$breakpoints: (
    'xs': $breakpoint-xs,
    'sm': $breakpoint-sm,
    'md': $breakpoint-md,
    'lg': $breakpoint-lg,
    'xl': $breakpoint-xl,
);

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    // Use a media query loop for breakpoints
    @each $breakpoint, $max-width in $breakpoints {
        @media (min-width: #{$max-width}) {
            max-width: $max-width;
        }
    }

    @media (max-width: $breakpoint-xs) {
        width: 80%;
    }
}

// mx-auto class
.mx-auto {
    margin-right: auto;
    margin-left: auto;
}

.pl-0 {
    padding-left: 0;
}

.no-scroll {
    overflow: hidden !important;
}
