@import '../../Style/breakpoints.scss';
@import '../../Style/theme.scss';

.Navbar {
    padding: 1.875rem 0;
    flex: 0 1 auto;

    .Button {
        padding: 0;
    }

    .BurgerMenu {
        @media (min-width: $breakpoint-sm) {
            display: none;
        }
        z-index: 20;
        flex: 0 1 auto;
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        span {
            display: block;
            background-color: $black;
            border: none;
            height: 3px;
            width: 100%;
            transition:
                transform 0.3s ease,
                opacity 0.3s ease;
        }

        &.-active {
            span:nth-child(1) {
                transform: translateY(4px) translateX(1px) rotate(45deg);
                position: relative;
                top: 8px;
            }

            span:nth-child(2) {
                opacity: 0;
            }

            span:nth-child(3) {
                transform: translateY(0px) translateX(1px) rotate(-45deg);
                position: relative;
                top: -8px;
            }
        }
    }

    &__content {
        max-width: 1496px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__buttonGroups {
        display: flex;
        position: relative;
        align-items: center;
        gap: 1.5rem;
        text-transform: uppercase;
        width: 200px;

        @media (max-width: $breakpoint-sm) {
            display: none;
        }

        &.-right {
            flex-direction: row-reverse;
        }
    }

    &__title {
        font-size: 2.3rem;
        font-weight: 500;
        letter-spacing: 0.25rem;

        text-align: center;
        cursor: pointer;
    }

    &__userMenu {
        position: absolute;
        width: max-content;
        top: 23px;
        right: 15px;
        background-color: $white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        z-index: 10;
        padding: 10px;
        display: flex;
        flex-direction: column;

        button {
            padding: 12px 20px;
            margin: 5px 0;
            border: none;
            background: none;
            text-align: left;
            cursor: pointer;
            &:hover {
                background-color: gray-100;
            }
        }
    }
}
