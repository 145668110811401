@import '../../Style/index.scss';

.Footer {
    background-color: $mercury-200;
    color: $mercury-950;
    padding: 3.75rem 4rem;
    height: 322px;
    overflow: hidden;
    flex: 0 1 auto;

    &__content {
        max-width: 1496px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        letter-spacing: 0.25rem;
        position: relative;
        z-index: 10;
    }

    &__policyLinks {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.6px;

        &--terms {
            margin-right: auto;
            margin-left: 11rem;
        }
    }

    &__socialMediaLinks {
        display: flex;
        gap: 1.2rem;
        align-items: center;
    }

    &__socialMediaLink {
        display: inline-block;
        height: 1.625rem;
    }

    &__socialMediaIcon {
        padding: 1px;
        height: 100%;
    }

    &__allRightsReserved {
        margin-left: 11rem;
        font-size: 16px;
        letter-spacing: 0.6px;
        font-weight: 300;
    }

    &__circle {
        position: relative;
        width: 90%;
        height: 1px;
        background-color: $emerald-400;
        margin-top: 20px;
        transform: translate(13%, 0%);
        &::before {
            content: '';
            position: absolute;
            left: 0%;
            top: 50%;
            transform: translate(-100%, -50%);
            width: 290px;
            height: 290px;
            background-color: transparent;
            border: 1px solid $emerald-400;
            border-radius: 50%;
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .Footer {
        height: auto;
        &__circle {
            display: none;
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .Footer {
        padding: 2rem;
        &__title {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
        }

        &__policyLinks {
            &--terms {
                margin: 0;
            }
        }

        &__allRightsReserved {
            margin: 0;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .Footer {
        padding: 2rem 1rem;
        &__row {
            flex-direction: column;
            align-items: start;
            gap: 1rem;
        }

        &__title {
            border-bottom: 1px solid $emerald-400;
            padding-bottom: 0.6rem;
            font-size: 2rem;
        }

        &__allRightsReserved {
            white-space: nowrap;
            position: relative;
            z-index: 10;
        }

        &__policyLinks {
            font-size: 1rem;
        }
    }
}
