@import '../../Style/index.scss';

.Button {
    cursor: pointer;

    &--text {
        padding: 0 1rem;
        transition: color 0.2s ease-in;

        &:hover,
        &:focus {
            color: $mercury-400;
        }
    }
    &--primary {
        padding: 1rem 2rem;
        border: 1px solid $black;
        width: 100%;
        text-align: center;
    }
}
