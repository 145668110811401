@import '../../Style/theme.scss';
.skeleton {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid $gray-300;
    border-radius: 8px;
    background-color: $gray-200;
}

.skeleton__image {
    width: 100%;
    height: 200px;
    background-color: $gray-300;
    border-radius: 8px;
}

.skeleton__details {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.skeleton__title,
.skeleton__price,
.skeleton__option,
.skeleton__description {
    height: 20px;
    background-color: $gray-300;
    border-radius: 4px;
}

.skeleton__title {
    width: 60%;
}

.skeleton__price {
    width: 30%;
}

.skeleton__option {
    width: 50%;
}

.skeleton__description {
    width: 100%;
    height: 60px;
}
