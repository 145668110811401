@import 'Style/index.scss';

html,
body {
    font-family: $font-family;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: $mercury-950;
}
