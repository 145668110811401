@import '../../Style/theme.scss';

.radioButton {
    > input {
        display: none;
        &:checked ~ label {
            background-color: $emerald-400;
            color: $white;
        }
    }

    > label {
        padding: 10px 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        color: $black;
        background-color: $white;
        font-size: 14px;
        cursor: pointer;
        display: block;
        max-width: fit-content;
    }
}
