@import '../../Style/theme.scss';

.breadCrumbs {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    text-transform: uppercase;
    margin-bottom: 25px;

    .links {
        font-weight: 700;
        color: $gray-900-translucent;
        cursor: pointer;
    }
}
