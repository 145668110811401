@import '../../../Style/index.scss';

.CategoryDrawer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 10;
    animation: slideDownAndFade 0.3s ease-in-out;

    &__overlay {
        width: 100%;
        height: 100vh;
        background-color: rgba($mercury-950, 0.6);
    }

    &__content {
        min-width: 25rem;
        width: 100%;
        background-color: $white;
        width: fit-content;
        height: 100%;
        padding: 5rem 3rem;
        transition: transform 0.3s ease-in-out;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 20;

        &--account {
            border-top: 1px solid $black;
        }
    }

    &__menuItem {
        cursor: pointer;
        padding: 0.625rem 0;
        text-transform: uppercase;
        font-size: 1.40625rem;
        font-weight: 700;
        line-height: 1.5625rem;
        letter-spacing: 0.6px;
    }

    &__menuLink {
        transition: color 0.2s ease-in;

        &:hover,
        &:focus {
            color: $mercury-400;
        }
        &--disable {
            color: $mercury-400;
            cursor: default;
        }
    }

    &--close {
        animation: slideDownAndFade 0.3s ease-in-out reverse;
    }
}

@keyframes slideDownAndFade {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
